// @ts-nocheck
import React, { useState, useEffect, useRef, useCallback } from 'react';
import "./ViewPDF.css";
import axios from 'axios';
import { useAuth } from './AuthProvider';
import {
  ZoomIn,
  ZoomOut,
  ChevronLeft,
  ChevronRight,
  Edit2,
  Save,
  FileText,
  SkipBack,
  SkipForward
} from 'lucide-react';

// Import PDF.js correctly
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';

import UTIF from 'utif';

// Set worker source
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.7.76/pdf.worker.mjs`;

const ViewPDF = () => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [pageDetails, setPageDetails] = useState(null);
  const [scale, setScale] = useState(1);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { user } = useAuth();

  // Refs
  const canvasRef = useRef(null);
  const renderTaskRef = useRef(null);
  const renderingRef = useRef(false);
  const pendingRenderRef = useRef(null);
  const initializedRef = useRef(false);
  const timeoutRef = useRef(null);
  const [imageData, setImageData] = useState(null); // Add this new state
  const currentBlobUrl = useRef(null); // Add this ref to track current blob URL

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const calculateFitDimensions = (originalWidth, originalHeight, containerWidth, containerHeight) => {
    const widthRatio = containerWidth / originalWidth;
    const heightRatio = containerHeight / originalHeight;
    const fitRatio = Math.min(widthRatio, heightRatio);

    return {
      width: Math.floor(originalWidth * fitRatio),
      height: Math.floor(originalHeight * fitRatio),
      scale: fitRatio
    };
  };

  const getContentType = (extension) => {
    const contentTypes = {
      pdf: 'application/pdf',
      tiff: 'image/tiff',
      tif: 'image/tiff',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png'
    };
    return contentTypes[extension] || 'application/octet-stream';
  };

  const clearCurrentDocument = useCallback(() => {
    if (pdfDocument) {
      pdfDocument.destroy();
      setPdfDocument(null);
    }

    if (currentBlobUrl.current) {
      URL.revokeObjectURL(currentBlobUrl.current);
      currentBlobUrl.current = null;
    }

    setImageUrl(null);
    setImageData(null);

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [pdfDocument]);

  const handlePdfFile = async (data) => {
    clearCurrentDocument();

    const pdfData = new Uint8Array(data);
    try {
      console.log('Loading PDF document...');
      const loadingTask = pdfjsLib.getDocument({ data: pdfData });
      const pdf = await loadingTask.promise;

      console.log('PDF document loaded, initializing...');
      setNumPages(pdf.numPages);
      setCurrentPage(1);
      setPdfDocument(pdf);

      console.log('PDF initialization complete');
    } catch (error) {
      console.error('PDF load error:', error);
      throw new Error('Failed to load PDF document');
    }
  };

  const handleTiffFile = async (data) => {
    try {
      console.log('Starting TIFF processing...');
      const buffer = new Uint8Array(data);
      const ifds = UTIF.decode(buffer);

      if (!ifds || ifds.length === 0) {
        throw new Error('Invalid TIFF file');
      }

      const firstPage = ifds[0];

      const canvas = canvasRef.current;
      const containerDiv = canvas.parentElement;

      const originalWidth = firstPage.t256?.[0] || 3030;
      const originalHeight = firstPage.t257?.[0] || 3484;

      const { width, height } = calculateFitDimensions(
        originalWidth,
        originalHeight,
        containerDiv.clientWidth - 40,
        containerDiv.clientHeight - 40
      );

      canvas.width = width;
      canvas.height = height;

      UTIF.decodeImage(buffer, firstPage);
      const rgba = UTIF.toRGBA8(firstPage);

      if (!rgba) {
        throw new Error('Failed to decode TIFF image data');
      }

      const ctx = canvas.getContext('2d');
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = originalWidth;
      tempCanvas.height = originalHeight;
      const tempCtx = tempCanvas.getContext('2d');

      const imgData = new ImageData(
        new Uint8ClampedArray(rgba),
        originalWidth,
        originalHeight
      );
      tempCtx.putImageData(imgData, 0, 0);

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(tempCanvas, 0, 0, originalWidth, originalHeight, 0, 0, width, height);

      setNumPages(1);
    } catch (error) {
      throw new Error(`Failed to load TIFF file: ${error.message}`);
    }
  };

  const handleImageFile = async (data, extension) => {
    try {
      clearCurrentDocument();

      // Store the image data in state
      setImageData({
        data,
        type: getContentType(extension)
      });

      setNumPages(1);
      setPdfDocument(null);
    } catch (error) {
      console.error('[handleImageFile] Error:', error);
      throw new Error(`Failed to load image file: ${error.message}`);
    }
  };

  const fetchFile = async (fileName) => {
    if (!canvasRef.current || !fileName) return;

    setLoading(true);
    setError(null);

    try {
      const extension = getFileExtension(fileName);
      setFileType(extension);

      const response = await axios.get(`/documents/${fileName}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': getContentType(extension),
        }
      });

      switch (extension) {
        case 'pdf':
          await handlePdfFile(response.data);
          break;
        case 'jpg':
        case 'jpeg':
        case 'png':
          await handleImageFile(response.data, extension);
          break;
        default:
          throw new Error('Unsupported file type');
      }
    } catch (error) {
      console.error('Error in fetchFile:', error);
      setError(`Failed to fetch file: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  const getPDFDetails = async (fileName, pageNumber) => {
    if (!fileName) return;
    try {
      const response = await axios.get(`/filedetails/${fileName}`, {
        params: { page_number: pageNumber }
      });
      setPageDetails(response.data);
    } catch (error) {
      console.error('Error fetching PDF details:', error);
    }
  };

  const renderImage = useCallback(async (imageData, scale) => {
    if (!imageData || !canvasRef.current) return;

    try {
      // Revoke previous blob URL if it exists
      if (currentBlobUrl.current) {
        URL.revokeObjectURL(currentBlobUrl.current);
        currentBlobUrl.current = null;
      }

      // Create new blob and URL
      const blob = new Blob([imageData.data], { type: imageData.type });
      const newBlobUrl = URL.createObjectURL(blob);
      currentBlobUrl.current = newBlobUrl;

      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
          const canvas = canvasRef.current;
          if (!canvas) {
            reject(new Error('Canvas not available'));
            return;
          }

          const ctx = canvas.getContext('2d');
          const containerDiv = canvas.parentElement;

          const { width, height } = calculateFitDimensions(
            img.width,
            img.height,
            containerDiv.clientWidth - 40,
            containerDiv.clientHeight - 40
          );

          canvas.width = width * scale;
          canvas.height = height * scale;

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.scale(scale, scale);
          ctx.drawImage(img, 0, 0, width, height);
          ctx.scale(1 / scale, 1 / scale);

          resolve();
        };

        img.onerror = () => {
          reject(new Error('Failed to load image'));
        };

        img.src = newBlobUrl;
      });
    } catch (error) {
      console.error('[renderImage] Error:', error);
      throw error;
    }
  }, []);

  // Consolidated effect for initialization and rendering
  useEffect(() => {
    let mounted = true;

    const render = async () => {
      if (!canvasRef.current) return;

      try {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        if (renderTaskRef.current) {
          renderTaskRef.current.cancel();
          renderTaskRef.current = null;
        }

        if (pdfDocument && fileType === 'pdf') {
          // PDF rendering logic remains the same
          timeoutRef.current = setTimeout(async () => {
            if (!mounted) return;
            try {
              const page = await pdfDocument.getPage(currentPage);
              const viewport = page.getViewport({ scale });
              const canvas = canvasRef.current;
              if (!canvas) return;

              const ctx = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              ctx.clearRect(0, 0, canvas.width, canvas.height);
              const renderTask = page.render({
                canvasContext: ctx,
                viewport: viewport,
              });

              renderTaskRef.current = renderTask;
              await renderTask.promise;
              renderTaskRef.current = null;
            } catch (error) {
              if (error.name !== 'RenderingCancelled') {
                console.error('[renderContent] PDF render error:', error);
              }
            }
          }, 100);
        } else if (imageData && ['jpg', 'jpeg', 'png'].includes(fileType)) {
          await renderImage(imageData, scale);
        }
      } catch (error) {
        console.error('[render] Error:', error);
        setError(`Failed to render content: ${error.message}`);
      }
    };

    render();

    return () => {
      mounted = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [pdfDocument, currentPage, scale, fileType, imageData, renderImage]);

  // Effect for updating PDF details when page changes
  useEffect(() => {
    if (files[currentFileIndex] && currentPage) {
      getPDFDetails(files[currentFileIndex], currentPage);
    }
  }, [files, currentFileIndex, currentPage]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      // Cleanup function
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
        renderTaskRef.current = null;
      }
      if (pdfDocument) {
        pdfDocument.destroy();
      }
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
        setImageUrl(null);
      }
    };
  }, [pdfDocument, imageUrl]);

  useEffect(() => {
    const initialize = async () => {
      if (!canvasRef.current || initializedRef.current) return;

      try {
        initializedRef.current = true;
        const response = await axios.get('/processedfiles');
        setFiles(response.data.files);

        if (response.data.files.length > 0) {
          await fetchFile(response.data.files[0]);
        }
      } catch (error) {
        console.error('Initialization error:', error);
        setError('Failed to initialize viewer');
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    return () => {
      clearCurrentDocument();
    };
  }, [clearCurrentDocument]);

  const handleNextFile = useCallback(async () => {
    console.log('[handleNextFile] Starting file transition');
    const nextFileIndex = (currentFileIndex + 1) % files.length;
    console.log(`[handleNextFile] Current index: ${currentFileIndex}, Next index: ${nextFileIndex}`);

    // Clear current document before loading new one
    clearCurrentDocument();

    setCurrentFileIndex(nextFileIndex);
    setCurrentPage(1);
    await fetchFile(files[nextFileIndex]);

    console.log('[handleNextFile] File transition complete');
  }, [currentFileIndex, files, clearCurrentDocument]);

  const handlePrevFile = useCallback(async () => {
    const prevFileIndex = (currentFileIndex - 1 + files.length) % files.length;

    // Clear current document before loading new one
    clearCurrentDocument();

    setCurrentFileIndex(prevFileIndex);
    setCurrentPage(1);
    await fetchFile(files[prevFileIndex]);
  }, [currentFileIndex, files, clearCurrentDocument]);

  const handleInputChange = (key, newValue) => {
    setPageDetails(prev => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleReferenceChange = (key, newValue) => {
    setPageDetails(prev => ({
      ...prev,
      References: {
        ...prev.References,
        [key]: newValue,
      },
    }));
  };

  const handleUpdateDetails = async () => {
    try {
      const response = await axios.post(`/updatefiledetails/${files[currentFileIndex]}`, {
        page_number: currentPage,
        details: pageDetails
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setPageDetails(response.data);
        setEditable(false);
      }
    } catch (error) {
      console.error('Error updating PDF details:', error);
    }
  };

  return (
    <div className="flex flex-row justify-between h-screen w-full" >
      <div className="w-8/12 flex flex-col items-center justify-start p-4" >
        <div className="flex items-center justify-center space-x-4 mb-4 bg-white p-2 rounded-lg shadow-sm w-full" >
          <button onClick={handlePrevFile} className="p-2 hover:bg-gray-100 rounded-md transition-colors" title="Previous File" >
            <SkipBack className="w-5 h-5 text-blue-600" />
          </button>
          < button
            onClick={() => setScale(prev => Math.max(prev - 0.25, 0.5))}
            className="p-2 hover:bg-gray-100 rounded-md transition-colors flex items-center"
            title="Zoom Out"
          >
            <ZoomOut className="w-5 h-5" />
          </button>
          < span className="text-sm text-gray-600" > {Math.round(scale * 100)} % </span>
          < button
            onClick={() => setScale(prev => Math.min(prev + 0.25, 3))}
            className="p-2 hover:bg-gray-100 rounded-md transition-colors flex items-center"
            title="Zoom In"
          >
            <ZoomIn className="w-5 h-5" />
          </button>

          {
            fileType === 'pdf' && pdfDocument && (
              <>
                <div className="h-6 border-r border-gray-300" />
                <button
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage <= 1
                  }
                  className="flex items-center px-3 py-1.5 bg-gray-100 hover:bg-gray-200 rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  <ChevronLeft className="w-4 h-4 mr-1" />
                  Previous
                </button>
                < span className="text-sm text-gray-600" >
                  Page {currentPage} of {numPages}
                </span>
                < button
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages))}
                  disabled={currentPage >= numPages}
                  className="flex items-center px-3 py-1.5 bg-gray-100 hover:bg-gray-200 rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  Next
                  < ChevronRight className="w-4 h-4 ml-1" />
                </button>
              </>
            )}
          <button
            onClick={handleNextFile}
            className="p-2 hover:bg-gray-100 rounded-md transition-colors"
            title="Next File"
          >
            <SkipForward className="w-5 h-5 text-blue-600" />
          </button>
        </div>
        < div className="w-full h-full overflow-auto" >
          <canvas
            ref={canvasRef}
            className="border border-gray-200 rounded-lg shadow-lg"
          />
        </div>
        {
          loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75" >
              <div className="text-gray-600" > Loading file...</div>
            </div>
          )
        }
        {
          error && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75" >
              <div className="text-red-600" > {error} </div>
            </div>
          )
        }
      </div>

      < div className="w-4/12 bg-gray-50 p-6 h-full overflow-auto border-l border-gray-200" >
        <div className="flex items-center justify-between mb-6" >
          <div>
            <h2 className="text-xl font-bold text-gray-800" >
              View pdfjsLib.js Document Details
            </h2>
            < p className="text-sm text-gray-600" >
              {files[currentFileIndex]}(Page {currentPage})
            </p>
          </div>
          < div className="flex gap-2" >
            <button
              onClick={() => setEditable(!editable)}
              className={`flex items-center px-4 py-2 rounded-md ${editable
                ? 'bg-gray-200 hover:bg-gray-300 text-gray-700'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
                } transition-colors`}
            >
              <Edit2 className="w-4 h-4 mr-2" />
              {editable ? 'Cancel' : 'Edit'}
            </button>
            {
              editable && (
                <button
                  onClick={handleUpdateDetails}
                  className="flex items-center px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md transition-colors"
                >
                  <Save className="w-4 h-4 mr-2" />
                  Save
                </button>
              )
            }
          </div>
        </div>

        {
          pageDetails && (
            <div className="space-y-6" >
              {
                Object.entries(pageDetails).map(([key, value]) => {
                  if (key !== "page_number" && key !== "References") {
                    return (
                      <div key={key} className="mb-2" >
                        <label className="block text-gray-700 text-sm font-semibold mb-1" >
                          {key.replace(/_/g, ' ').toUpperCase()}
                        </label>
                        < input
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50"
                          type="text"
                          value={value || ""
                          }
                          onChange={(e) => handleInputChange(key, e.target.value)
                          }
                          disabled={!editable}
                        />
                      </div>
                    );
                  }
                  return null;
                })}

              {
                pageDetails.References && (
                  <div className="mb-6" >
                    <h3 className="text-lg font-semibold mb-3" > References </h3>
                    {
                      Object.entries(pageDetails.References).map(([key, value]) => (
                        <div key={key} className="mb-2" >
                          <label className="block text-gray-700 text-sm font-semibold mb-1" >
                            {key.replace(/_/g, ' ').toUpperCase()}
                          </label>
                          < input
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50"
                            type="text"
                            value={value || ""}
                            onChange={(e) => handleReferenceChange(key, e.target.value)
                            }
                            disabled={!editable}
                          />
                        </div>
                      ))}
                  </div>
                )}
            </div>
          )}
      </div>
    </div>
  );
};

export default ViewPDF;