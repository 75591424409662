import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import {
  ZoomIn,
  ZoomOut,
  ChevronLeft,
  ChevronRight,
  Edit2,
  Save,
  FileText
} from 'lucide-react';

// Import PDF.js correctly
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';

import UTIF from 'utif';

// Set worker source
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.7.76/pdf.worker.mjs`;


const ViewDetails = () => {
  const { fileName } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const canvasRef = useRef(null);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [pageDetails, setPageDetails] = useState(null);
  const [scale, setScale] = useState(1);
  const [editable, setEditable] = useState(false);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isCanvasReady, setIsCanvasReady] = useState(false);
  const renderTaskRef = useRef(null);
  const renderingRef = useRef(false);
  const pendingRenderRef = useRef(null);
  const timeoutRef = useRef(null);
  const [originalImageDimensions, setOriginalImageDimensions] = useState(null);

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const calculateFitDimensions = useCallback((originalWidth, originalHeight, containerWidth, containerHeight) => {
    const widthRatio = containerWidth / originalWidth;
    const heightRatio = containerHeight / originalHeight;
    const fitRatio = Math.min(widthRatio, heightRatio);

    return {
      width: Math.floor(originalWidth * fitRatio),
      height: Math.floor(originalHeight * fitRatio),
      scale: fitRatio
    };
  }, []);

  const fetchFile = async (fileName) => {
    console.log('fetchFile called with:', fileName);
    if (!isCanvasReady) {
      console.log('Canvas not ready, aborting fetch');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const extension = getFileExtension(fileName);
      console.log('File extension:', extension);
      setFileType(extension);

      console.log('Making API request for file:', fileName);
      const response = await axios.get(`/documents/${fileName}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': getContentType(extension),
        }
      });

      console.log('File fetched successfully, processing...');
      switch (extension) {
        case 'pdf':
          console.log('Processing PDF file...');
          await handlePdfFile(response.data);
          break;
        case 'tiff':
        case 'tif':
          console.log('Processing TIFF file...');
          await handleTiffFile(response.data);
          break;
        case 'jpg':
        case 'jpeg':
        case 'png':
          await handleImageFile(response.data, extension);
          break;
        default:
          throw new Error('Unsupported file type');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error in fetchFile:', error);
      setError(`Failed to fetch file: ${error.message}`);
      setLoading(false);
    }
  };

  const getContentType = (extension) => {
    const contentTypes = {
      pdf: 'application/pdf',
      tiff: 'image/tiff',
      tif: 'image/tiff',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png'
    };
    return contentTypes[extension] || 'application/octet-stream';
  };

  const getPDFDetails = async (fileName, pageNumber) => {
    if (!fileName) return;
    try {
      const response = await axios.get(`/filedetails/${fileName}`, {
        params: { page_number: pageNumber }
      });
      setPageDetails(response.data);
    } catch (error) {
      console.error('Error fetching PDF details:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    }
  };

  const handlePdfFile = async (data) => {
    const pdfData = new Uint8Array(data);
    try {
      console.log('Loading PDF document...');
      const loadingTask = pdfjsLib.getDocument({ data: pdfData });
      const pdf = await loadingTask.promise;

      console.log('PDF document loaded, initializing...');
      setNumPages(pdf.numPages);
      setCurrentPage(1);
      setPdfDocument(pdf);

      console.log('PDF initialization complete');
    } catch (error) {
      console.error('PDF load error:', error);
      throw new Error('Failed to load PDF document');
    }
  };

  const handleTiffFile = async (data) => {
    try {
      console.log('Starting TIFF processing...');
      const buffer = new Uint8Array(data);
      const ifds = UTIF.decode(buffer);

      if (!ifds || ifds.length === 0) {
        throw new Error('Invalid TIFF file');
      }

      const firstPage = ifds[0];
      console.log('TIFF decoded:', firstPage);

      const canvas = canvasRef.current;
      const containerDiv = canvas.parentElement;

      // Get original dimensions from the TIFF metadata
      const originalWidth = firstPage.t256?.[0] || 3030;
      const originalHeight = firstPage.t257?.[0] || 3484;

      // Calculate dimensions to fit the container
      const { width, height } = calculateFitDimensions(
        originalWidth,
        originalHeight,
        containerDiv.clientWidth - 40, // subtract padding
        containerDiv.clientHeight - 40 // subtract padding
      );

      console.log('Setting canvas dimensions:', { width, height });
      canvas.width = width;
      canvas.height = height;

      // Decode TIFF data
      console.log('Decoding TIFF image...');
      UTIF.decodeImage(buffer, firstPage);
      const rgba = UTIF.toRGBA8(firstPage);

      if (!rgba) {
        throw new Error('Failed to decode TIFF image data');
      }

      console.log('Creating ImageData...');
      const ctx = canvas.getContext('2d');

      // Create a temporary canvas for the original size
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = originalWidth;
      tempCanvas.height = originalHeight;
      const tempCtx = tempCanvas.getContext('2d');

      // Put the original image data
      const imgData = new ImageData(
        new Uint8ClampedArray(rgba),
        originalWidth,
        originalHeight
      );
      tempCtx.putImageData(imgData, 0, 0);

      // Clear main canvas and draw scaled image
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(tempCanvas, 0, 0, originalWidth, originalHeight, 0, 0, width, height);

      setNumPages(1);
      console.log('TIFF render complete');

    } catch (error) {
      console.error('TIFF decode error:', error);
      throw new Error(`Failed to load TIFF file: ${error.message}`);
    }
  };

  const handleImageFile = async (data, extension) => {
    try {
      // Clear any existing image URL
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }

      const blob = new Blob([data], { type: getContentType(extension) });
      const url = URL.createObjectURL(blob);

      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
          const canvas = canvasRef.current;
          if (!canvas) {
            reject(new Error('Canvas not available'));
            return;
          }

          const ctx = canvas.getContext('2d');
          const containerDiv = canvas.parentElement;

          // Store original dimensions
          const imgDimensions = {
            width: img.width,
            height: img.height
          };

          // Calculate initial dimensions
          const { width: baseWidth, height: baseHeight } = calculateFitDimensions(
            imgDimensions.width,
            imgDimensions.height,
            containerDiv.clientWidth - 40,
            containerDiv.clientHeight - 40
          );

          // Set canvas dimensions and draw initial image
          canvas.width = baseWidth;
          canvas.height = baseHeight;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            img,
            0,
            0,
            imgDimensions.width,
            imgDimensions.height,
            0,
            0,
            baseWidth,
            baseHeight
          );

          // Update state after successful render
          setOriginalImageDimensions(imgDimensions);
          setImageUrl(url);
          setNumPages(1);
          resolve();
        };

        img.onerror = () => {
          URL.revokeObjectURL(url);
          reject(new Error('Failed to load image'));
        };

        img.src = url;
      });
    } catch (error) {
      console.error('Error in handleImageFile:', error);
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
      throw new Error(`Failed to load image file: ${error.message}`);
    }
  };

  const renderContent = useCallback(async () => {
    if (!canvasRef.current) {
      console.log('Canvas not available');
      return;
    }

    if (renderingRef.current) {
      console.log('Already rendering, queuing new render');
      pendingRenderRef.current = { page: currentPage, scale };
      return;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (renderTaskRef.current) {
      console.log('Cancelling existing render task');
      renderTaskRef.current.cancel();
      renderTaskRef.current = null;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    try {
      renderingRef.current = true;
      switch (fileType) {
        case 'pdf':
          if (!pdfDocument) {
            console.log('PDF document not loaded');
            return;
          }
          console.log('Rendering PDF page', currentPage);

          const page = await pdfDocument.getPage(currentPage);
          const viewport = page.getViewport({ scale });

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const renderTask = page.render({
            canvasContext: ctx,
            viewport: viewport,
          });

          renderTaskRef.current = renderTask;
          await renderTask.promise;
          renderTaskRef.current = null;
          break;

        case 'tiff':
        case 'tif':
          // For TIFF files, we only need to handle scaling
          if (scale !== 1) {
            const currentWidth = canvas.width / scale;
            const currentHeight = canvas.height / scale;

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

            const newWidth = canvas.width * scale;
            const newHeight = canvas.height * scale;

            // Resize canvas
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw scaled image
            ctx.putImageData(imageData, 0, 0);
            break;


          }
          break;
        case 'jpg':
        case 'jpeg':
        case 'png':
          if (imageUrl && originalImageDimensions) {
            const img = new Image();
            await new Promise((resolve) => {
              img.onload = () => {
                const containerDiv = canvas.parentElement;
                // Calculate base dimensions (unscaled)
                const { width: baseWidth, height: baseHeight } = calculateFitDimensions(
                  originalImageDimensions.width,
                  originalImageDimensions.height,
                  containerDiv.clientWidth - 40,
                  containerDiv.clientHeight - 40
                );

                // Apply scale to base dimensions
                const scaledWidth = baseWidth * scale;
                const scaledHeight = baseHeight * scale;

                // Update canvas size
                canvas.width = scaledWidth;
                canvas.height = scaledHeight;

                // Clear and draw scaled image
                ctx.clearRect(0, 0, scaledWidth, scaledHeight);
                ctx.drawImage(
                  img,
                  0,
                  0,
                  originalImageDimensions.width,
                  originalImageDimensions.height,
                  0,
                  0,
                  scaledWidth,
                  scaledHeight
                );
                resolve();
              };
              img.src = imageUrl;
            });
          }
          break;

      }
    } catch (error) {
      if (error.name === 'RenderingCancelled') {
        console.log('Rendering was cancelled');
      } else {
        console.error('Error in renderContent:', error);
        setError(`Failed to render ${fileType.toUpperCase()} file: ${error.message}`);
      }
    } finally {
      renderingRef.current = false;
      if (pendingRenderRef.current) {
        const { page, scale } = pendingRenderRef.current;
        pendingRenderRef.current = null;
        if (page === currentPage) {
          setTimeout(() => renderContent(), 50);
        }
      }
    }
  }, [fileType, pdfDocument, currentPage, scale, calculateFitDimensions]);

  // For initial file fetch
  useEffect(() => {
    const checkCanvas = () => {
      if (canvasRef.current) {
        console.log('Canvas initialized');
        setIsCanvasReady(true);
      }
    };
    checkCanvas();
  }, []);

  // Effect for initial file fetch
  useEffect(() => {
    if (fileName && isCanvasReady) {
      console.log('Canvas ready, fetching file:', fileName);
      fetchFile(fileName);
    }
  }, [fileName, isCanvasReady]);

  useEffect(() => {
    if (!canvasRef.current) return;

    const renderTimeout = setTimeout(() => {
      if (pdfDocument) {
        renderContent();
      } else if (['jpg', 'jpeg', 'png'].includes(fileType) && imageUrl && originalImageDimensions) {
        renderContent();
      }
    }, 100);

    return () => {
      clearTimeout(renderTimeout);
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
        renderTaskRef.current = null;
      }
    };
  }, [
    pdfDocument,
    currentPage,
    scale,
    renderContent,
    fileType,
    imageUrl,
    originalImageDimensions
  ]);

  // Effect for PDF details
  useEffect(() => {
    if (fileName && currentPage) {
      getPDFDetails(fileName, currentPage);
    }
  }, [fileName, currentPage]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
        renderTaskRef.current = null;
      }
      if (pdfDocument) {
        console.log('Cleaning up PDF document');
        pdfDocument.destroy();
      }
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [pdfDocument, imageUrl]);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  };

  const zoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.25, 3));
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.25, 0.5));
  };

  const handleEditable = () => {
    setEditable(!editable);
  };

  const handleInputChange = (key, newValue) => {
    setPageDetails((prevDetails) => ({
      ...prevDetails,
      [key]: newValue,
    }));
  };

  const handleReferenceChange = (key, newValue) => {
    setPageDetails((prevDetails) => ({
      ...prevDetails,
      References: {
        ...prevDetails.References,
        [key]: newValue,
      },
    }));
  };

  const handleUpdateDetails = async () => {
    try {
      const response = await axios.post(`/updatefiledetails/${fileName}`, {
        page_number: currentPage,
        details: pageDetails
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setPageDetails(response.data);
        setEditable(false);
      } else {
        console.error('Failed to update PDF details');
      }
    } catch (error) {
      console.error('Error updating PDF details:', error);
    }
  };

  const renderFieldGroup = (title, fields) => (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-3">{title}</h3>
      <div className="space-y-3">
        {fields.map(([key, value]) => (
          <div key={key} className="mb-2">
            <label className="block text-gray-700 text-sm font-semibold mb-1">
              {key.replace(/_/g, ' ').toUpperCase()}
            </label>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50"
              type="text"
              value={value || ""}
              onChange={(e) => handleInputChange(key, e.target.value)}
              disabled={!editable}
            />
          </div>
        ))}
      </div>
    </div>
  );


  return (
    <div className="flex flex-row justify-between h-screen w-full">
      <div className="w-8/12 flex flex-col items-center justify-start p-4">
        <div className="flex items-center justify-center space-x-4 mb-4 bg-white p-2 rounded-lg shadow-sm w-full">
          <button
            onClick={() => setScale(prev => Math.max(prev - 0.25, 0.5))}
            className="p-2 hover:bg-gray-100 rounded-md transition-colors flex items-center"
            title="Zoom Out"
          >
            <ZoomOut className="w-5 h-5" />
          </button>
          <span className="text-sm text-gray-600">{Math.round(scale * 100)}%</span>
          <button
            onClick={() => setScale(prev => Math.min(prev + 0.25, 3))}
            className="p-2 hover:bg-gray-100 rounded-md transition-colors flex items-center"
            title="Zoom In"
          >
            <ZoomIn className="w-5 h-5" />
          </button>

          {fileType === 'pdf' && pdfDocument && (
            <>
              <div className="h-6 border-r border-gray-300" />
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage <= 1}
                className="flex items-center px-3 py-1.5 bg-gray-100 hover:bg-gray-200 rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                <ChevronLeft className="w-4 h-4 mr-1" />
                Previous
              </button>
              <span className="text-sm text-gray-600">
                Page {currentPage} of {numPages}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages))}
                disabled={currentPage >= numPages}
                className="flex items-center px-3 py-1.5 bg-gray-100 hover:bg-gray-200 rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                Next
                <ChevronRight className="w-4 h-4 ml-1" />
              </button>
            </>
          )}
        </div>
        {/* Always render the canvas first */}
        <div className="w-full h-full overflow-auto">
          <canvas
            ref={canvasRef}
            className="border border-gray-200 rounded-lg shadow-lg"
          />
        </div>
        {/* Show loading state as an overlay if needed */}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
            <div className="text-gray-600">Loading file... {fileName}</div>
          </div>
        )}
        {error && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
            <div className="text-red-600">{error}</div>
          </div>
        )}

      </div>

      <div className="w-4/12 bg-gray-50 p-6 h-full overflow-auto border-l border-gray-200">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-xl font-bold text-gray-800">
              View Details: Document Details
            </h2>
            <p className="text-sm text-gray-600">
              {fileName} (Page {currentPage})
            </p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleEditable}
              className={`flex items-center px-4 py-2 rounded-md ${editable
                ? 'bg-gray-200 hover:bg-gray-300 text-gray-700'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
                } transition-colors`}
            >
              {editable ? (
                <>
                  <Edit2 className="w-4 h-4 mr-2" />
                  Cancel
                </>
              ) : (
                <>
                  <Edit2 className="w-4 h-4 mr-2" />
                  Edit
                </>
              )}
            </button>
            {editable && (
              <button
                onClick={handleUpdateDetails}
                className="flex items-center px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md transition-colors"
              >
                <Save className="w-4 h-4 mr-2" />
                Save
              </button>
            )}
          </div>
        </div>

        {pageDetails && (
          <div className="space-y-6">
            {/* Address Fields */}
            {renderFieldGroup("Addresses", [
              ['consignee_address', pageDetails.consignee_address],
              ['ship_from_address', pageDetails.ship_from_address],
              ['bill_to_address', pageDetails.bill_to_address]
            ])}

            {/* Weight Fields */}
            {renderFieldGroup("Weight Information", [
              ['weight', pageDetails.weight],
              ['gross_weight', pageDetails.gross_weight]
            ])}

            {/* References */}
            {pageDetails.References && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-3">References</h3>
                <div className="space-y-3">
                  {Object.entries(pageDetails.References)
                    .filter(([key, value]) => key != null && value != null)
                    .map(([key, value]) => (
                      <div key={key} className="mb-2">
                        <label className="block text-gray-700 text-sm font-semibold mb-1">
                          {key.replace(/_/g, ' ').toUpperCase()}
                        </label>
                        <input
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50"
                          type="text"
                          value={value || ""}
                          onChange={(e) => handleReferenceChange(key, e.target.value)}
                          disabled={!editable}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDetails;